import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../hooks/useAuth";
import { getordersByUID, getUserByUID,setCustomerFormData } from "../../../store/reducer/Home";

const AddressList = () => {
  const dispatch = useDispatch();

  const { ordersData, userData } = useSelector((state) => state.apiData);
  const { login, user } = useAuth();

  const navigate = useNavigate();

  // console.log("userData", userData?.result?.addressDetails);

  // State to track selected address
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddress, setNewAddress] = useState(false);

  useEffect(() => {
    console.log('user',user)

    // if(!user){
    //   navigate("/checkUser");
    // }

    if(user){
      dispatch(getUserByUID(user?.userID))
      .then((res) => {
        if (res.payload.code == 200) {
          login(res?.payload?.result);
        } else if (res.payload.code == 401) {
        }
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });
    }

  }, []);

  // Function to handle address selection
  const handleSelect = (address) => {
    console.log('address',address)
    setNewAddress(true)
    setSelectedAddress(address);
    dispatch(setCustomerFormData(address));

  };

  // Function to handle address addition
  const handleAddAddress = () => {
    navigate("/customerinfo");
  };
  const handleContinue = () => {
    if(newAddress){
      navigate("/orderSummary");
      setNewAddress(false)
    }else{
      window.alert('Please select address')
      // navigate("/orderSummary");
    }
    
  };


  return (
    <div style={styles.container}>
      <div style={styles.addressContainer}>
        <h2>Select an Address</h2>
        <ul style={styles.list}>
          {user?.addressDetails.map((address) => (
            <li
              key={address._id}
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom: "1px solid gray",
                marginBottom: "20px",
                backgroundColor:
                  selectedAddress?._id === address._id ? "#d3f8d3" : "#fff",
              }}
              onClick={() => handleSelect(address)}
            >
              <p>Full Name: {address.fullName}</p>
              <p>Phone No: {address.phoneNo}</p>
              <p>
                House-Flat No: {address.houseNoBuildingName} / {address.flatNo}
              </p>
              <p>
                City-State: {address.city}/{address.state}
              </p>
              <p>Pic code: {address.zipCode}</p>

              {/* {address.street}, {address.city}, {address.zip} */}
            </li>
          ))}
        </ul>

        {/* {selectedAddress && (
          <div style={styles.selectedAddress}>
            <h3>Selected Address:</h3>
            <p>{selectedAddress.street}, {selectedAddress.city}, {selectedAddress.zip}</p>
          </div>
        )} */}

        <div style={styles.addNewAddressContainer}>

          <button onClick={handleAddAddress} style={{...styles.addButton,backgroundColor:'rgb(251 189 5)'}}>
            Add New Address
          </button>
          <button onClick={handleContinue}  style={styles.addButton}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    height: "auto",
  },
  addressContainer: {
    width: "80%",
    maxWidth: "600px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
  list: {
    listStyleType: "none",
    padding: "0",
  },
  listItem: {
    padding: "10px",
    cursor: "pointer",
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
  },
  radioButton: {
    marginRight: "10px",
  },
  selectedAddress: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#d3f8d3",
    borderRadius: "5px",
  },
  addNewAddressContainer: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#f9f9f9",
    borderRadius: "5px",
  },
  input: {
    width: "100%",
    padding: "10px",
    margin: "5px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "10px",
  },
};

export default AddressList;

import OrderDetails from "./OrderDetails";

const Order = ({ ordersData,clickTab,handleBack }) => {
  return (
    <div className="order-list">
      <h1>My Orders</h1>
      {/* <button onClick={()=>handleBack()}>Back</button> */}
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => handleBack()}
        >
          Back
        </button>
<></>
      </div>
      {ordersData.map((order) => (
        <OrderDetails key={order._id} order={order}/>
      ))}
    </div>
  );
};

export default Order;

import React, {  useEffect } from "react";
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const OrderSuccess = () => {

  useEffect(() => {
    // window.localStorage.setItem('customerFormData', JSON.stringify(null));
    // window.localStorage.setItem('selectedData', JSON.stringify(null));

  }, []);
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        minHeight: '80vh' 
      }}
    >
      <Typography variant="h4" gutterBottom>
        Order Placed Successfully!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your order. We will notify you shortly about the shipment. 
      </Typography>
      <Button 
        variant="contained" 
        component={Link} 
        to="/profile?order=success" 
        sx={{ 
          marginTop: '2rem', 
          padding: '1rem 2rem' 
        }}
      >
        View order details
      </Button>
    </Box>
  );
};

export default OrderSuccess;
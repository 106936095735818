import React, { useEffect } from "react";

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Routes,
  Route,
  Router,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Navigation from "./Navigation";
import StickyLayout from "./StickyLayout";
import ProductList from "./container/microGreens/product/ProductList";
// import MicrogreensTable from "./Microgreens";
import ProductDescriptionS from "./container/microGreens/product/ProductDescriptionS";
// import ProductNavigation from './ProductNavigation';
import CustomerInfoForm from "./CustomerInfoForm";
import About from "./common/Aboutus";
import Blog from "./container/microGreens/blog/Blog";
import TextEditor from "./container/microGreens/blog/TextEditor";
import Benefits from "./container/microGreens/benefits/Benefits";
import { fetchMicrogreen } from "./store/reducer/Home";
import CustomerForm from "./container/microGreens/customer/CustomerForm";
import OrderSummary from "./container/microGreens/orders/Summery";
import OrderSuccess from "./container/microGreens/orders/OrderSuccess";
import Profile from "./container/microGreens/profile/Profile";
import CheckUser from "./container/microGreens/profile/CheckUser";
import AddressList from "./container/microGreens/customer/AddressList";
import ContactSupport from "./container/microGreens/customer/ContactSupport";
import EasyReturnPolicy from "./common/EasyReturnPolicy";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Shop", path: "/shop" },
  { label: "Microgreens", path: "/shop/microgreens" },
  { label: "Details", path: "/shop/microgreens/details" },
];
const App = () => {
  const dispatch = useDispatch();

  const { mData } = useSelector((state) => state.apiData);

  useEffect(() => {
    dispatch(fetchMicrogreen());
  }, []);

  const handleNavigate = (path) => {
    console.log("Navigating to:", path);
    // Use react-router's `useNavigate` or another navigation logic here
    // Example: navigate(path) if using `useNavigate` hook
  };

  return (
      <StickyLayout>
        <div>
          <Routes>
            <Route path="/" element={<ProductList products={mData} />} />
            <Route
              path="/products"
              element={<ProductList products={mData} />}
            />
            <Route
              path="/productsDetails/:id"
              element={<ProductDescriptionS />}
            />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/customerInfoForm" element={<CustomerInfoForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactSupport />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/postBlog" element={<TextEditor />} />
            <Route path="/customerinfo" element={<CustomerForm />} />
            <Route path="/orderSummary" element={<OrderSummary />} />
            <Route path="/orderSuccess" element={<OrderSuccess />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/checkUser" element={<CheckUser />} />
            <Route path="/addressList" element={<AddressList />} />
            <Route path="/return-policy" element={<EasyReturnPolicy />} />

            

          </Routes>
        </div>
      </StickyLayout>
 
  );
};

export default App;

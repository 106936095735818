import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import LeafLoading from "../../../common/LeafLoading";



// Single Product Card Component
const MicrogreenProductCard = (props) =>{

  const location = useLocation();

  useEffect(() => {
    window.dataLayer.push({
      'event': 'pageview',
      'page': location.pathname + location.search + location.hash
    });
  }, [location]); 


 return (
  <div className="product-card" onClick={() => props.handleProductClick(props.product)}>
    <div className="product-image-wrapper">
      {/* <img src={props.product.baseURL+props.product?.images[0]?.url} alt={props.product.name} className="product-image" /> */}
          {/* <img src={"https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg"} alt={props.product.name} className="product-image" /> */}
       <img src={props.product?.image} alt={props.product.name} className="product-image" />

    </div>
    <div className="product-details">
      <h5 className="product-name">{props.product.productName}</h5>
      {/* <p className="product-price-">Price ₹<span className="tdecoration">{props.product.msp.toFixed(2)}</span></p>
    { !props.product.specialPrice&& <p className="product-price">Selling Price ₹{props.product.sellingPrice.toFixed(2)}</p>}
      {props.product.specialPrice&&<p className="product-price">Special Price ₹{props.product.specialPrice.toFixed(2)}</p>} */}

      <p className="product-description lineHeight">{props.product.description}</p>
      <button className="add-to-cart-btn">ORDER NOW</button>
    </div>
  </div>
)};


const ProductList = ({ products }) => {
const navigate = useNavigate();
const {loading} = useSelector((state) => state.apiData)

console.log('loading',loading)
const handleProductClick = (pro) => {
  console.log('id',pro._id)
  // navigate(`/productsDetails/${pro.id}`);
  navigate(
    `/productsDetails/${pro._id}`,
    {
      state: {
        ...pro
      }
    }
  )
};

if (loading == 'pending') {
  return (
    <LeafLoading/>
  );
}

  return (
    <div className="product-section">
    <div className="product-list">
      {products.map((product, index) => (
        <MicrogreenProductCard key={index} product={product} handleProductClick={handleProductClick}/>
      ))}
    </div>
  </div>
  );
};

export default ProductList;

import React from "react";
import "./EasyReturnPolicy.css"; // Import the custom CSS for styling

const EasyReturnPolicy = () => {
  return (
    <div className="easy-return-policy-container">
      <h2>Our Easy Return Policy</h2>
      <p>
        We want you to be completely satisfied with your purchase. If for any
        reason you're not happy with your order, we offer a simple and hassle-free
        return policy.
      </p>
      <div className="policy-steps">
        <h3>How to Return an Item:</h3>
        <ol>
          <li>
            <strong>Contact Us:</strong> Reach out to our customer support team
            within 30 days of receiving your order.
          </li>
          <li>
            <strong>Return the Item:</strong> Pack the item securely in its
            original packaging and include the receipt or proof of purchase.
          </li>
          <li>
            <strong>Get a Refund or Exchange:</strong> Once we receive the returned
            item, we will process a refund or offer an exchange, depending on your
            preference.
          </li>
        </ol>
      </div>
      <div className="important-notice">
        <h3>Important Notice:</h3>
        <ul>
          <li>Items must be returned in their original condition.</li>
          <li>We do not accept returns on sale items or personalized products.</li>
          <li>Shipping fees are non-refundable.</li>
        </ul>
      </div>
      <p>If you have any questions, please don't hesitate to contact us!</p>
    </div>
  );
};

export default EasyReturnPolicy;

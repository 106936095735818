import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Typography, 
  Box,
  Grid,
  Paper,

} from '@mui/material';
import LeafBorder from "../../../common/LeafBorder";
const OrderDetails = ({ order }) => {
    // console.log('order details 000-->',order)
  return (
    <>
     <Box sx={{ mt: 3 }}>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Product: {order?.productName}
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Price: ₹{order?.maxP.toFixed(2)}
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Special Price: ₹{order.totalPrice.toFixed(2)}
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Weight: {order.weightOrder}g
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Delivery charge: 50
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      subscription: {order?.subscriptionType['repeat']}
      </Typography>

      <Typography variant="body1" sx={{ mt: 2 }}>
      Description: {order?.subscriptionType['description']}
      </Typography>

      <Typography variant="h6" sx={{ mt: 2 }}>
        Order Total: ₹{order?.totalPrice.toFixed(2)}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        Order ID: {order?.orderID}
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }}>
        Order Date: {new Date(order?.createdAt).toLocaleString()} 
      </Typography>
      {/* Add more order details like shipping address, payment method, etc. */}
    </Box>
    <LeafBorder/>
    </>
   
  );
};

const styles = theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: `${theme.spacing.unit * 3}px`
  },
  paper: {
    padding: theme.spacing.unit,
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing.unit
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`
  }
});


export default OrderDetails;
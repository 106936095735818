import React,{useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import LeafLoading from "../../../common/LeafLoading";

import { saveOrder ,setCustomerFormData,setExampleData} from "../../../store/reducer/Home";
const OrderSummary = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const { customerFormData, selectedData } = useSelector(
    (state) => state.apiData
  );

  const calculateTotal = () => {
    let total = 0;
    // products.forEach((product) => {
    //   total += product.price * product.quantity;
    // });
    return total;
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    if (user) {
      if(customerFormData?.["phoneNo"]){
        setLoading(true)
        let payload = {
          ...selectedData,
          phone: customerFormData?.["phoneNo"],
          destinationAddress: customerFormData,
          userID: user?.["userID"],
        };
        // console.log("payload", payload);
        dispatch(saveOrder(payload))
          .then((res) => {
            setLoading(false)
            console.error("success res:", res);
            if (res.payload.code == 200) {
              dispatch(setCustomerFormData(null));
              dispatch(setExampleData(null));
               navigate("/orderSuccess");
            } else if (res.payload.code == 401) {
            }
          })
          .catch((error) => {
            console.error("Error fetching location:", error);
          });
      }else{
        navigate("/addressList");
      }

    }else{
      navigate("/checkUser");
    }
  };

  const calculateWithDicTotal = (price) => {
    return Math.floor(price);
  };

  // console.log("selectedData", selectedData);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
       {loading && <LeafLoading />}
      {selectedData?.productName ? (
        <Box style={{ backgroundColor: "#fff", padding: "23px" }}>
          <Typography style={{ textAlign: "center" }} variant="h4" gutterBottom>
            {location?.state?location?.state:"Order Summary"}
          </Typography>

          <Grid container>
            <Grid item xs={12} md={12}>
              {/* <Typography variant="h6">Order Summary</Typography> */}
              <List>
                <ListItem>
                  <ListItemText primary={`Product`} />

                  <ListItemText
                    primary={`${selectedData.productName}  ${selectedData.selectedWeight}g`}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText primary={`Weight`} />

                  <ListItemText primary={`${selectedData.selectedWeight}g`} />
                </ListItem>

                <ListItem>
                  <ListItemText primary={`Subscription`} />
                  <ListItemText
                    primary={`${selectedData.selectedSubs?.repeat}`}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    secondary={selectedData.selectedSubs?.description}
                  />
                </ListItem>
                <ListItem>
                  {/* <ListItemText primary={`Delivery`} /> */}
                  Delivery<s className="actual-price">₹50</s>{" "}
                  <span className="off-percentage">FREE</span>
                </ListItem>

                <ListItem>
                  <ListItemText primary="Payment" />
                  <ListItemText primary={`COD`} />
                </ListItem>

                <ListItem>
                  <ListItemText primary="Price" />
                  <ListItemText
                    primary={<s>₹{calculateWithDicTotal(selectedData.maxP)}</s>}
                  />
                  {/* <ListItemText primary={`₹${calculateTotal().toFixed(2)}`} /> */}
                </ListItem>

                <ListItem>
                  <ListItemText primary="Discount" />
                  <ListItemText primary={`₹${selectedData.totalDiscount}`} />
                  {/* <ListItemText primary={`₹${calculateTotal().toFixed(2)}`} /> */}
                </ListItem>
                {/* Add shipping, tax, and discount calculations here */}
                <ListItem>
                  <ListItemText primary="Total" />
                  <ListItemText primary={`₹${selectedData.totalPrice}`} />
                </ListItem>
              </List>

              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#2f7d31" }}
                  onClick={handleSubmit}
                  fullWidth
                >
                  Place Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <div>
          <div>Cart is empty please add to cart!</div>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#2f7d31", marginTop: "29px" }}
            onClick={() => navigate("/")}
            fullWidth
          >
            Continue shopping
          </Button>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;

import React from 'react';
import './LeafBorder.css'; // import the custom CSS

function LeafBorder() {
  return (
    <div className="leaf-border"></div> // Empty div with border-bottom and leaf

  );
}

export default LeafBorder;

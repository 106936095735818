import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";

import "./Profile.css";
import { getordersByUID ,getUserByUID} from "../../../store/reducer/Home";
import NotificationSwitch from "./NotificationSwitch";
import Order from './Order'
import { useAuth } from "../../../hooks/useAuth";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ordersData,userData } = useSelector((state) => state.apiData);
  const {  logout,user } = useAuth();
  const location = useLocation();

  const [selectedSection, setSelectedSection] = useState("profile");
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const [clickTab, setClickTab] = useState(false);

   // Create a new URLSearchParams object to extract query parameters
   const queryParams = new URLSearchParams(location.search);
  
   // Get a specific query parameter by key
   const paramValue = queryParams.get('order'); // Example query parameter 

  // console.log("queryParams", paramValue);
  // console.log("userData", userData);


  useEffect(() => {
    // console.log('=======',user)

    if(paramValue=='success'){
      setSelectedSection('orders');

      setClickTab(true);
    }
    if(user?.['userID']){
      // console.log('=======',user['userID'])
      dispatch(getordersByUID(user?.['userID']));
      dispatch(getUserByUID(user?.['userID']));
    }else{
      navigate("/checkUser");
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleShoHidePf = (e) => {
    setUpdateProfileModal(!updateProfileModal);
  };

  const handleUpdateProfile = () => {
    alert("Profile updated successfully!");
    // Add logic to update profile in the backend
  };

  const handleSetSelectedSection = (e) => {
    setSelectedSection(e);

    setClickTab(true);
    // console.log(e);
    // Add logic to update profile in the backend
  };

  const renderProfileForm = () => (
    <div>
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
      {/* <div className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }>.jhjjh</div> */}
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => setClickTab(false)}
        >
          Back
        </button>
     
        {/* <button className="floatr" onClick={handleShoHidePf}>
          {updateProfileModal ? "View Profile" : "Update Profile"}
        </button> */}
      </div>

      {updateProfileModal ? (
        // <div className="profile-section">
        //   <form className="profile-form">
        //     <label>
        //       Name:
        //       <input
        //         type="text"
        //         name="name"
        //         value={user.name}
        //         onChange={handleInputChange}
        //       />
        //     </label>
        //     <label>
        //       Email:
        //       <input
        //         type="email"
        //         name="email"
        //         value={user.email}
        //         onChange={handleInputChange}
        //       />
        //     </label>
        //     <label>
        //       Phone:
        //       <input
        //         type="text"
        //         name="phone"
        //         value={user.phone}
        //         onChange={handleInputChange}
        //       />
        //     </label>
        //     {/* <label>
        //   Address:
        //   <textarea
        //     name="address"
        //     value={user.address}
        //     onChange={handleInputChange}
        //   ></textarea>
        // </label> */}
        //     <button type="button" onClick={handleUpdateProfile}>
        //       Update Profile
        //     </button>
        //   </form>
        // </div>
        <div></div>
      ) : (
        <div>
          <p>Name: {user?.['firstName']+' '+user?.['lastName']} </p>
          <p>Phone: {user?.['phoneNo']}</p>
          <p>Email: {user?.['email']}</p>
          {/* <p>Subscriptions : Daily</p> */}
          {/* <p>Address : {userData['phoneNo']}</p> */}
        </div>
      )}
    </div>
  );

  // const renderOrders = () => (
  //   <>
  //     <div className={clickTab ? "flex-row" : "updateProfileButton"}>
  //       <button
  //         className={
  //           clickTab ? "display-profile-layout " : "hide-profile-layout"
  //         }
  //         onClick={() => setClickTab(false)}
  //       >
  //         Back
  //       </button>

  //     </div>
  //     <div className="orders-section">
  //       <h2>My Orders</h2>
  //       <table className="orders-table">
  //         <thead>
  //           <tr>
  //             <th>Order ID</th>
  //             <th>Product</th>
  //             <th>Date</th>
  //             <th>Status</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {ordersData.map((order) => (
  //             <tr key={order.id}>
  //               <td>{order.id}</td>
  //               <td>{order.product}</td>
  //               <td>{order.date}</td>
  //               <td>{order.status}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </>
  // );

  const renderAddress = () => (
    <>
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => setClickTab(false)}
        >
          Back
        </button>

      </div>
      <div className="orders-section">
        <h2>My Address</h2>
        {user?.['addressDetails'].map((address) => (
          <div key={address._id} className="borderBottom">
            {/* <button className="updateProfileButton" onClick={handleShoHidePf}>
              Update address
            </button> */}

            <p>
              <b>Society:</b> {address?.houseNoBuildingName}
            </p>
            <p>
              <b>Flat No:</b> {address?.flatNo}
            </p>
            <p>
              <b>City/Pin:</b>{address.city} / {address?.zipCode}
            </p>
            <p>
              <b>State</b>
              {address?.state}
            </p>
          </div>
        ))}
      </div>
    </>
  );

  const renderPaymentMethod = () => (
    <div className="">
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => setClickTab(false)}
        >
          Back
        </button>

      </div>
      <h2>Payment method</h2>
      <p>
        <b>Cash on delivery:</b> For your <b>best experience and trust</b>, we
        currently offer Cash on Delivery as our preferred payment method. Pay
        conveniently upon receiving your order.
      </p>
    </div>
  );

  const renderNotification = () => (
    <section>
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => setClickTab(false)}
        >
          Back
        </button>
<></>
      </div>
      <h2>Notification</h2>
      <NotificationSwitch />
    </section>
  );

  const renderSupport = () => (
    <section>
      <div className={clickTab ? "flex-row" : "updateProfileButton"}>
        <button
          className={
            clickTab ? "display-profile-layout showhide" : "hide-profile-layout showhide"
          }
          onClick={() => setClickTab(false)}
        >
          Back
        </button>
<></>
      </div>
      <h2>Please use below information for your support</h2>
      <p>
        <b>Email to:</b>{" "}
        <a href="mailto:aparna@treelix.in">Send email to support</a>
      </p>
      <p>
        <b>Phone no:</b> Mon to Friday: 7488355801
      </p>
      <p>
        <b>Web:</b> https://treelix.in
      </p>
    </section>
  );

  const renderNavigation = () => (
    <div className="side-navigation">
      {/* <h3>Menu</h3> */}
      <ul>
        <li onClick={() => handleSetSelectedSection("profile")}>Profile</li>
        <li onClick={() => handleSetSelectedSection("orders")}>Orders</li>
        <li onClick={() => handleSetSelectedSection("address")}>Address</li>
        <li onClick={() => handleSetSelectedSection("paymentMethods")}>
          Payment Methods
        </li>
        <li onClick={() => handleSetSelectedSection("notification")}>
          Notification
        </li>
        <li onClick={() => handleSetSelectedSection("support")}>Support</li>

        <li onClick={() => logout()}>Logout</li>
      </ul>
    </div>
  );

  return (
    <div className="profile-container">
      {/* <h1>My Profile</h1> */}
      <div className="profile-layout">
        <section
          className={
            clickTab ? "hide-profile-layout " : "display-profile-layout "
          }
        >
          {renderNavigation()}
        </section>
        <section
          className={
            !clickTab ? "hide-profile-layout" : "display-profile-layout "
          }
        >
          <div className="content-area">
            {selectedSection === "profile" && renderProfileForm()}
            {selectedSection === "orders" && <Order ordersData={ordersData} clickTab={clickTab} handleBack={() => setClickTab(false)}/>}
            {selectedSection === "address" && renderAddress()}
            {selectedSection === "paymentMethods" && renderPaymentMethod()}
            {selectedSection === "notification" && renderNotification()}
            {selectedSection === "support" && renderSupport()}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Profile;

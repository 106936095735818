// apiDataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import AsyncStorage from '@react-native-async-storage/async-storage';

// const usersasass=async()=>{
//   const user = await AsyncStorage.getItem('userData');
//   let userVal =user?JSON.parse(user):null
//   return userVal
// }

const baseURL = "https://app-hvpdly3esq-uc.a.run.app/v1/api";
  //const baseURL = "http://127.0.0.1:5001/nodeproj-1533800975811/us-central1/app/v1/api"

export const fetchMicrogreen = createAsyncThunk("get/microGreens", async () => {
  const response = await fetch(`${baseURL}/microGreenproducts`);
  const data = await response.json();
  // await AsyncStorage.setItem('userData', JSON.stringify(data?.result));
  return data?.result;
});

export const fetchBlogs = createAsyncThunk("get/blogs", async () => {
  const response = await fetch(`${baseURL}/blogs`);
  const data = await response.json();
  return data?.result;
});

export const fetchByidMicrogreen = createAsyncThunk(
  "get/microGreensById",
  async (data, thunkAPI) => {
    try {
      let datacrt = {
        id: data,
      };
      const response = await axios.post(
        `${baseURL}/microGreenproductById`,
        datacrt
      );
      return response?.data?.result[0]; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const saveUser = createAsyncThunk(
  "add/newUser",
  async (data, thunkAPI) => {
    try {
      let datacrt = {
        data: data
      };
      const response = await axios.post(
        `${baseURL}/addMGUser`,
        data
      );
      return response?.data; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const saveOrder = createAsyncThunk(
  "add/orderData",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/checkoutMGOrder`,
        data
      );
      return response?.data; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getordersByUID = createAsyncThunk(
  "get/getordersByUID",
  async (data, thunkAPI) => {
    try {
      let datacrt = {
        // id: data
        "userID":data
      };
      const response = await axios.post(
        `${baseURL}/getMGOrderByUID`,
        datacrt
      );
      // console.log('getMGOrderByUID====',response)
      return response?.data?.result; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUserByUID = createAsyncThunk(
  "get/getUserByUID",
  async (data, thunkAPI) => {
    try {
      let payload = {"userID":data}
      const response = await axios.post(
        `${baseURL}/getMGUserByUID`,
        payload
      );
      return response?.data; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addSupport = createAsyncThunk(
  "get/addSupport",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${baseURL}/addSupport`,
        data
      );
      return response?.data; // Return the response data on success
    } catch (error) {
      // Handle errors and reject the promise with an error message
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



const apiDataSlice = createSlice({
  name: "apiData",
  initialState: {
    API_baseURL: baseURL,
    data: {
      result: [],
    },
    selectedData:localStorage.getItem('selectedData') ? JSON.parse(localStorage.getItem('selectedData')) : {},
    customerFormData:localStorage.getItem('customerFormData') ? JSON.parse(localStorage.getItem('customerFormData')) : {},
    mData: [],
    sData: null,
    blogs: [],
    userData:{},
    saveOrderRes:{},
    ordersData:[],
    userData:{}
  },
  reducers: {
    // // Add any other non-async actions if needed
    setExampleData: (state, action) => {
      // console.log('updatE cart', action.payload)
      localStorage.setItem('selectedData', JSON.stringify(action.payload));
      state.selectedData = action.payload;
    },

    setCustomerFormData: (state, action) => {
      // console.log('updatE customerFormData', action.payload)
      localStorage.setItem('customerFormData', JSON.stringify(action.payload));
        state.customerFormData = action.payload;
    },
    
    resetSearch: (state, action) => {
      state.searchData.result = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMicrogreen.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchMicrogreen.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.mData = action.payload;
      })
      .addCase(fetchMicrogreen.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(fetchByidMicrogreen.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchByidMicrogreen.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.sData = action.payload;
      })
      .addCase(fetchByidMicrogreen.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(fetchBlogs.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(saveUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(saveUser.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userData = action.payload;
        // console.log('action.payload',action.payload)
        
      })
      .addCase(saveUser.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
        state.userData = action.payload;
      })

      .addCase(saveOrder.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(saveOrder.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.saveOrderRes = action.payload;
        // console.log('action.payload',action.payload)
        
      })
      .addCase(saveOrder.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(getordersByUID.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getordersByUID.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.ordersData = action.payload;
        // console.log('action.payload',action.payload)
        
      })
      .addCase(getordersByUID.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(getUserByUID.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getUserByUID.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userData = action.payload;
        // console.log('action.payload',action.payload)
        
      })
      .addCase(getUserByUID.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      })

      .addCase(addSupport.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addSupport.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userData = action.payload;
        // console.log('action.payload',action.payload)
        
      })
      .addCase(addSupport.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.payload;
      });

  },
});

export const { setExampleData,setCustomerFormData, resetSearch /* any other actions you need */ } =
  apiDataSlice.actions;
export default apiDataSlice.reducer;

import React, { useState } from 'react';
import './ContactSupport.css'; // Import the styles
import {
    addSupport
  } from "../../../store/reducer/Home";
  import { useDispatch } from "react-redux";
  import { useAuth } from "../../../hooks/useAuth";

function ContactSupport() {
    const dispatch = useDispatch();
    const { user } = useAuth();

  // State to hold form values
  const [formData, setFormData] = useState({
    queryShortDescription: '',
    queryDescription: '',
    email: user?.email||'',
    phoneNo: user?.phoneNo||'',
    userID:user?.userID||''
  });


  // State to handle form submission success/error
  const [status, setStatus] = useState('');

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation (you can expand this)
    if (!formData.queryShortDescription || !formData.email || !formData.phoneNo) {
      setStatus('Please fill in all fields.');
      return;
    }

    // Here, you would typically send the data to your backend.
    console.log('Form Submitted:', formData);
    dispatch(addSupport(formData))
    .then((res) => {
      if(res?.payload?.code==200){
        setStatus('Your message has been sent successfully!');
      }else if(res?.payload?.code==401){
        console.error("Error somthing went wrong please mail us on aparna@treelix.in");
      }

    })
    .catch((error) => {
      console.error("Error fetching location:", error);
    });

    // Reset form and show success message
    setFormData({
      queryShortDescription: '',
      queryDescription: '',
    });

  };

  return (
    <div className="contact-support">
      <h2>Contact Support</h2>
      {status && <p>{status}</p>} {/* Display success/error message */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Short Description:</label>
          <input
            type="text"
            name="queryShortDescription"
            value={formData.queryShortDescription}
            onChange={handleChange}
            placeholder="Short queryDescription of the issue"
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            name="queryDescription"
            value={formData.queryDescription}
            onChange={handleChange}
            placeholder="Detailed queryDescription of the issue"
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your email address"
          />
        </div>
        <div>
          <label>Mobile:</label>
          <input
            type="tel"
            name="phoneNo"
            value={formData.phoneNo}
            onChange={handleChange}
            placeholder="Your phoneNo number"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default ContactSupport;

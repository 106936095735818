import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./CheckUser.css";
import CustomerForm from "../customer/CustomerForm";
import { getordersByUID, getUserByUID } from "../../../store/reducer/Home";
import { useAuth } from "../../../hooks/useAuth";
import LeafLoading from "../../../common/LeafLoading";

const CheckUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login, user } = useAuth();

  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/, ""); // Allow only numbers

    setMobile(value);
  };

  console.log("user", user);

  useEffect(() => {
    console.log("user", user);

    if (user) {
      navigate("/addressList");
    }

    // if(user){
    //   dispatch(getUserByUID(user?.userID))
    //   .then((res) => {
    //     if (res.payload.code == 200) {
    //       login(res?.payload?.result);
    //     } else if (res.payload.code == 401) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching location:", error);
    //   });
    // }
  }, []);

  const handleCheckUser = (event) => {
   if(!mobile){
    return window.alert('Please enter mobile number')
   }else if(mobile.length<10){
    return window.alert('Please enter valied mobile number')
   }
   
   
    event.preventDefault();
    setLoading(true);
    dispatch(getUserByUID(mobile))
      .then((res) => {
        setLoading(false);
        // console.error("success res:", res);
        if (res.payload.code == 200) {
          login(res?.payload?.result);
    
          navigate("/orderSummary");
        } else if (res.payload.code == 401) {
          navigate("/customerinfo", { state: mobile})
          // navigate("/customerinfo");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching location:", error);
      });
  };

  return (
    <div className="check-user-container input-group">
      {loading && <LeafLoading />}
      <h4>Enter Mobile Number</h4>

      <div className="phone-input-container">
        <span className="country-code">+91</span>
        <input
          type="text"
          placeholder="Enter mobile number"
          value={mobile}
          onChange={handleInputChange}
          maxLength="10"
        />
      </div>

      <button onClick={handleCheckUser} disabled={loading}>
        {loading ? "Checking..." : "Continue"}
      </button>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default CheckUser;


import React, { useState, useEffect } from 'react';
import './LeafLoading.css'; // Import the custom CSS for animation

const Loader = () => {
  return (
 <div className='loader-overlay'>
    <div class="corners">
	<div class="corner corner--1"></div>
	<div class="corner corner--2"></div>
	<div class="corner corner--3"></div>
	<div class="corner corner--4"></div>
</div>
   </div>

  );
};

export default Loader;





import React, { useState } from 'react';
import Switch from '@mui/material/Switch';

const NotificationSwitch = () => {
  const [isNotificationsOn, setIsNotificationsOn] = useState(true); 

  const handleSwitchChange = (event) => {
    setIsNotificationsOn(event.target.checked);
  };

  return (
    <div>
      <Switch
        checked={isNotificationsOn}
        onChange={handleSwitchChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {isNotificationsOn ? 'Notifications On' : 'Notifications Off'}
    </div>
  );
};

export default NotificationSwitch;